import(/* webpackMode: "eager" */ "/app/app/css/style.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/ui/header.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@14.2.8_next@14.2.5_react-dom@18.2.0_react@18.2.0__react@18.2.0__react@18.2.0/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@14.2.8_next@14.2.5_react-dom@18.2.0_react@18.2.0__react@18.2.0__react@18.2.0/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@14.2.8_next@14.2.5_react-dom@18.2.0_react@18.2.0__react@18.2.0__react@18.2.0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.5_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.5_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-inter\",\"display\":\"swap\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.5_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Architects_Daughter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-architects-daughter\",\"weight\":\"400\",\"display\":\"swap\"}],\"variableName\":\"architects_daughter\"}");
