'use client';

import Link from 'next/link';
import MobileMenu from './mobile-menu';
import Image from 'next/image';
import Logo from '@/public/images/sarah_sweeps_logo.png';
import { usePathname } from 'next/navigation';

export default function Header() {
  const pathname = usePathname();
  const isActive = (path: string) =>
    pathname === path ? 'text-sky-500' : 'text-gray-800';

  return (
    <header className="absolute w-full z-30 bg-white shadow-md">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="flex items-center justify-between">
          {/* Site branding */}
          <div className="shrink-0 mr-4">
            {/* Logo */}
            <Link href="/" className="block" aria-label="Cruip">
              <Image
                src={Logo}
                alt="Sarah Sweeps Logo"
                width={80}
                height={80}
                layout="fixed"
                quality={100}
                priority
                sizes="(max-width: 64px) 64px, (max-width: 128px) 128px, (max-width: 256px) 256px, (max-width: 512px) 512px, 1024px"
              />
            </Link>
          </div>

          {/* Desktop navigation */}
          <nav className="hidden md:flex md:grow">
            {/* Desktop sign in links */}
            <ul className="flex grow justify-end flex-wrap items-center">
              <li>
                <Link
                  href="/"
                  className={`font-medium hover:text-sky-500 px-4 py-3 flex items-center transition duration-150 ease-in-out ${isActive('/')}`}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  href="/about"
                  className={`font-medium hover:text-sky-500 px-4 py-3 flex items-center transition duration-150 ease-in-out ${isActive('/about')}`}
                >
                  About
                </Link>
              </li>
              <li>
                <Link
                  href="/contact"
                  className={`font-medium hover:text-sky-500 px-4 py-3 flex items-center transition duration-150 ease-in-out ${isActive('/contact')}`}
                >
                  Contact
                </Link>
              </li>
            </ul>
          </nav>

          <MobileMenu />
        </div>
      </div>
    </header>
  );
}
